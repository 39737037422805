<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <!-- <v-col cols="12" sm="12" md="4" lg="2">
          <v-select
            outlined
            flat
            dense
            background-color="white"
            :items="listType"
            v-model="params.type"
            label="Type"
          ></v-select>
        </v-col> -->
        <v-col cols="12" sm="12" md="4" lg="2">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="End Date"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2">
          <v-btn color="primary" @click="search" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="success" @click="download" class="mr-2">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filter"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="12" sm="12" md="8" lg="8">
                  <v-text-field
                    flat
                    dense
                    outlined
                    background-color="white"
                    v-model="filter"
                    placeholder="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.no="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:item.haveNpwp="{ item }">
              {{ item.haveNpwp ? "Y" : "N" }}
            </template>
            <template v-slot:item.bp="{ item }">
              {{ item.bp ? "Y" : "N" }}
            </template>
            <template v-slot:item.skb="{ item }">
              {{ item.skb ? "Y" : "N" }}
            </template>
            <template v-slot:item.total="{ item }">
              {{ formatPrice(item.total) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import fileDownload from "js-file-download";
import moment from "moment";

export default {
  name: "ppn",
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      params: {
        startDate: moment().format("yyyy-MM-DD"),
        endDate: moment().format("yyyy-MM-DD"),
      },
      listType: [
        { value: 0, text: "PPN Keluaran" },
        { value: 1, text: "PPN Masukan" },
        { value: 2, text: "PPN Masukan Import" },
        { value: 3, text: "All" },
        { value: 5, text: "PPN" },
      ],
      items: [],
      headers: [
        {
          text: "No",
          align: "center",
          value: "no",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Masa Pajak",
          align: "center",
          value: "taxAge",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Tahun Pajak",
          align: "center",
          value: "taxYear",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Tgl Pemotongan",
          align: "center",
          value: "taxDate",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Ber-NPWP ? (Y/N)",
          align: "center",
          value: "haveNpwp",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "NPWP (tanpa format/tanda baca)",
          align: "left",
          value: "npwp",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "NIK (tanpa format/tanda baca)",
          align: "left",
          value: "nik",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Nama Lengkap (HURUF BESAR)",
          align: "left",
          value: "fullName",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Kabupaten-Kota (HURUF BESAR)",
          align: "center",
          value: "city",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Provinsi (HURUF BESAR)",
          align: "center",
          value: "state",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Nomor Telp",
          align: "left",
          value: "phone",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Kode Objek Pajak",
          align: "center",
          value: "code",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Penandatangan BP Pengurus ?",
          align: "center",
          value: "bp",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Penghasilan Bruto",
          align: "right",
          value: "total",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Mendapatkan Fasilitas ? (N/SKB/DTP)",
          align: "center",
          value: "skb",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Nomor SKB",
          align: "left",
          value: "skbNumber",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Nomor Aturan DTP",
          align: "left",
          value: "dtpNumber",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "NTPN DTP",
          align: "left",
          value: "ntpn",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
      filter: "",
    };
  },
  methods: {
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/pph", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async download() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/pphExcel", this.params)
        .then(response => {
          fileDownload(response.data, "pph.xlsx");
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid grey;
}
</style>
